import flatMap from 'lodash/flatMap';
import moment, {MomentInput} from 'moment-timezone';

import VALIDATION_MESSAGES from '@/constants/ValidationMessages';

import {durationToSeconds, getDateTimeFormats} from './timeFormatter';

const {DATE_FORMAT} = getDateTimeFormats();

// TODO: Validate if using using moment().toISOString() can get rid of the deprecation warning by writting dates according  the ISO 8601 standard
const generateValidateDateMessage = (value: string, minDate?: string, maxDate?: string) => {
    let errorMessage;
    if (!value) {
        return errorMessage;
    }

    const currentDate = moment(value, DATE_FORMAT);
    const splittedValue = value.split('/');
    const [month, date, year] = splittedValue;
    if (splittedValue.length === 3 && month && date && year && year.length > 1 && currentDate.isValid()) {
        if (maxDate && !moment(maxDate, DATE_FORMAT).add(1, 'd').isAfter(moment(currentDate, DATE_FORMAT), 'day')) {
            errorMessage = VALIDATION_MESSAGES.maxDate(maxDate);
            return errorMessage;
        }

        if (
            minDate &&
            !moment(minDate, DATE_FORMAT).subtract(1, 'd').isBefore(moment(currentDate, DATE_FORMAT), 'day')
        ) {
            errorMessage = VALIDATION_MESSAGES.minDate(minDate);
            return errorMessage;
        }

        return errorMessage;
    } else {
        errorMessage = VALIDATION_MESSAGES.wrongDateFormat;
        return errorMessage;
    }
};

const isOutsideRangeValidation = (day: MomentInput) => {
    return moment(day).isBefore(moment().startOf('day'), 'day');
};

type Specification = {defaultAppointmentArrivalWindow?: string};

const calculateArrivalWindow = (data: Specification[]) => {
    const transformedArrivalWindowList = data?.map(({defaultAppointmentArrivalWindow}) => ({
        arrivalWindowInSeconds: durationToSeconds(defaultAppointmentArrivalWindow),
        originalArrivalWindow: defaultAppointmentArrivalWindow,
    }));
    const shortestArrivalWindow = transformedArrivalWindowList?.reduce(
        (acc, cur) => (cur.arrivalWindowInSeconds > acc.arrivalWindowInSeconds ? acc : cur),
        {} as (typeof transformedArrivalWindowList)[0],
    );

    return shortestArrivalWindow.originalArrivalWindow;
};

const calculateArrivalWindowByDate = (
    ordersByDate: {[s: string]: unknown} | ArrayLike<unknown>,
    activityKey?: string,
) => {
    return Object.entries(ordersByDate).reduce((acc, [date, orderInfo]) => {
        const activitiesListInDate = Object.values(orderInfo).reduce((acc, {activities}) => {
            const activitiesList = flatMap(activities, activityKey);
            return [...acc, ...activitiesList];
        }, []);

        const arrivalWindow = calculateArrivalWindow(activitiesListInDate);

        return {...acc, [date]: arrivalWindow};
    }, {});
};

export {calculateArrivalWindow, calculateArrivalWindowByDate, generateValidateDateMessage, isOutsideRangeValidation};
